import { gql } from "@apollo/client";

export const COLORS = gql`
  query {
    colors
  }
`;

export const SIZES = gql`
  query {
    sizes
  }
`;

export const GET_PRODUCTS_PF = gql`
  query Products(
    $limit: Int
    $skip: Int
    $search: String
    $categoryId: String
    $color: [String]
    $size: [String]
    $storeId: String
  ) {
    products(
      limit: $limit
      skip: $skip
      search: $search
      categoryId: $categoryId
      color: $color
      size: $size
      storeId: $storeId
    ) {
      id
      name
      type
      avgRating
      NewArrival
      price
      thumbnail
      type
      Store {
        id
        name
        profilePic
        avgRating
        Address {
          state
          country
          phone
        }
        User {
          id
        }
      }
      Category {
        id
        name
      }
      Variants {
        id
        name
        size
        color
        availability_status
        currency
        price
        preview_url
        thumbnail_url
      }
    }
  }
`;
