import axios from "axios";
import { toast } from "react-toastify";

let currencyRatesCache = null;

export function getCurrencyRate(currencyName) {
  // If currency rates are already cached, return immediately
  if (currencyRatesCache && currencyRatesCache[currencyName]) {
    // console.log(currencyRatesCache[currencyName], "ca");

    return currencyRatesCache[currencyName];
  }

  // Initiate the asynchronous network request
  axios
    .get(`https://api.exchangerate-api.com/v4/latest/USD`)
    .then((response) => {
      const data = response.data;

      if (data.rates && data.rates[currencyName]) {
        // Cache the fetched currency rates
        currencyRatesCache = data.rates;

        return data.rates[currencyName];
      } else {
        toast.error(`Currency '${currencyName}' not found in response data.`);
        return null; // Or handle the error case accordingly
      }
    })
    .catch((error) => {
      console.error("Error fetching currency rate:", error);
      // Handle error gracefully, e.g., return a default value or throw a specific error
      return null; // Or throw a custom error with more details
    });
}
