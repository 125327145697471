import { useDispatch, useSelector } from "react-redux";
import {
  getProducts,
  setSearchQuery,
} from "../../../store/slices/filters-slice";

const MobileSearch = () => {
  const dispatch = useDispatch();
  const searchQuery = useSelector((state) => state.filters?.searchQuery);
  return (
    <div className="offcanvas-mobile-search-area">
      <form>
        <input
          type="search"
          value={searchQuery}
          onChange={(e) => {
            dispatch(setSearchQuery(e.target.value));
            dispatch(getProducts());
          }}
          placeholder="Search ..."
        />
        <button type="submit">
          <i className="fa fa-search" />
        </button>
      </form>
    </div>
  );
};

export default MobileSearch;
