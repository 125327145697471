import { createListenerMiddleware } from "@reduxjs/toolkit";
import {
  getProducts,
  increaseProductsLimit,
  resetPartialFilters,
  setCategoryId,
  setSearchQuery,
} from "../slices/filters-slice";

const productsFiltersListeners = createListenerMiddleware();

productsFiltersListeners.startListening({
  actionCreator: setCategoryId,
  effect: async (action, apis) => {
    apis.cancelActiveListeners();
    apis.dispatch(resetPartialFilters());
    apis.dispatch(getProducts());
  },
});

productsFiltersListeners.startListening({
  actionCreator: setSearchQuery,
  effect: (action, apis) => {
    apis.cancelActiveListeners();
    apis.dispatch(resetPartialFilters());
    apis.dispatch(getProducts());
  },
});

productsFiltersListeners.startListening({
  actionCreator: increaseProductsLimit,
  effect: async (action, apis) => {
    apis.cancelActiveListeners();
    apis.dispatch(getProducts());
  },
});

export { productsFiltersListeners };
