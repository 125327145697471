import { Suspense, lazy } from "react";
import { Navigate, useRoutes } from "react-router-dom";
import AuthLayout from "../layouts/LoginLayout";
import AuthGuard from "../guards/AuthGuard";

const Loadable = (Component) => (props) => {
  return (
    <Suspense
      fallback={
        <div className="airomnishop-preloader-wrapper">
          <div className="airomnishop-preloader">
            <span></span>
            <span></span>
          </div>
        </div>
      }
    >
      <Component {...props} />
    </Suspense>
  );
};

export default function Router() {
  return useRoutes([
    { path: "/", element: <Home />, index: true },
    { path: "/FAQs", element: <FAQs />, index: true },
    { path: "/PrivacyPolicy", element: <PrivacyPolicy />, index: true },
    { path: "/TermsOfUse", element: <TermsOfUse />, index: true },
    { path: "shop", element: <Shop /> },
    { path: "product/:id", element: <Product /> },
    { path: "stores", element: <Store /> },
    { path: "store/:id", element: <StoreDetail /> },
    { path: "blog", element: <Blog /> },
    { path: "about", element: <About /> },
    { path: "contact", element: <Contact /> },

    { path: "login", element: <LoginRegister /> },
    { path: "register/user", element: <UserRegister /> },
    { path: "register/seller", element: <SellerRegister /> },
    { path: "forgetpassword", element: <ForgetPassword /> },
    { path: "cart", element: <Cart /> },

    { path: "404", element: <NotFound /> },
    { path: "otp", element: <OTPConfirm /> },
    { path: "*", element: <Navigate to="/404" /> },
    {
      path: "dashboard",
      element: (
        <AuthGuard>
          <AuthLayout />
        </AuthGuard>
      ),
      children: [
        { path: "my-account", element: <MyAccount /> },
        { path: "BecomeSeller", element: <BecomeSeller /> },
        { path: "order/list", element: <OrderList /> },
        { path: "order/detail/:id", element: <OrderDetail /> },
        { path: "address", element: <AdressList /> },
        { path: "address/new", element: <AdressCreateUpdate /> },
        { path: "address/edit/:id", element: <AdressCreateUpdate /> },
        { path: "chat", element: <Chat /> },
        { path: "messages/:id", element: <ChatBox /> },
        { path: "checkout", element: <Checkout /> },
        { path: "ordersuccess", element: <OrderSuccess /> },
        { path: "notifications", element: <NotificationsList /> },
        { path: "wishlist", element: <Wishlist /> },
        { path: "compare", element: <Compare /> },
      ],
    },
  ]);
}

// home pages
const PrivacyPolicy = Loadable(lazy(() => import("../pages/PrivacyPolicy")));
// home pages
const TermsOfUse = Loadable(lazy(() => import("../pages/TermsOfUse")));
// home pages
const FAQs = Loadable(lazy(() => import("../pages/FAQs")));
// home pages
const Home = Loadable(lazy(() => import("../pages/home")));

// shop pages
const Shop = Loadable(lazy(() => import("../pages/shop")));

// product pages
const Product = Loadable(lazy(() => import("../pages/shop-product")));

// blog pages
const Blog = Loadable(lazy(() => import("../pages/blog")));

// chat
const Chat = Loadable(lazy(() => import("../pages/chat")));
const ChatBox = Loadable(lazy(() => import("../pages/chat/ChatBox")));
// Adresss
const AdressList = Loadable(lazy(() => import("../pages/Account/AddressList")));
const AdressCreateUpdate = Loadable(
  lazy(() => import("../pages/Account/AddressCreateUpdate"))
);
// Cart
const Cart = Loadable(lazy(() => import("../pages/Cart/Cart")));
// Account
const MyAccount = Loadable(lazy(() => import("../pages/Account/MyAccount")));
// Checkout
const Checkout = Loadable(lazy(() => import("../pages/Checkout/Checkout")));
// contact
const Contact = Loadable(lazy(() => import("../pages/contact/Contact")));
// store
const Store = Loadable(lazy(() => import("../pages/store")));
const StoreDetail = Loadable(lazy(() => import("../pages/storedetails")));
// other pages
const About = Loadable(lazy(() => import("../pages/other/About")));
const LoginRegister = Loadable(lazy(() => import("../pages/login")));
const UserRegister = Loadable(lazy(() => import("../pages/register")));
const SellerRegister = Loadable(lazy(() => import("../pages/sellerregister")));
const BecomeSeller = Loadable(lazy(() => import("../pages/BecomeSeller")));

const Wishlist = Loadable(lazy(() => import("../pages/other/Wishlist")));
const Compare = Loadable(lazy(() => import("../pages/other/Compare")));
const OTPConfirm = Loadable(lazy(() => import("../pages/OtpConfirm")));

const OrderList = Loadable(lazy(() => import("../pages/Order/list")));
const OrderDetail = Loadable(lazy(() => import("../pages/Order/Details")));
const OrderSuccess = Loadable(
  lazy(() => import("../pages/other/OrderSuccess"))
);

const NotificationsList = Loadable(lazy(() => import("../pages/Notifcation")));

const NotFound = Loadable(lazy(() => import("../pages/other/NotFound")));
const ForgetPassword = Loadable(lazy(() => import("../pages/ForgetPassword")));
