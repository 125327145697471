import { gql } from "@apollo/client";

export const LOGIN_USER = gql`
  mutation LoginAsUser($email: String!, $password: String!) {
    loginAsUser(email: $email, password: $password) {
      token
      otpRequired
      user {
        email
        firstName
        emailVerifiedAt
        id
        lastName
        role
        dob
      }
    }
  }
`;

export const ME = gql`
  query Me {
    me {
      id
      role
      firstName
      lastName
      profilePic
      dob
      email
      emailVerifiedAt
      country
      countryCode
      phone
      phoneVerifiedAt
      createdAt
    }
  }
`;
export const REGISTER_AS_USER = gql`
  mutation registerAsUser(
    $firstName: String!
    $lastName: String!
    $email: String!
    $password: String!
    $dob: String
    $profilePic: String
    $country: String
    $countryCode: String
    $phone: String
  ) {
    registerAsUser(
      firstName: $firstName
      lastName: $lastName
      email: $email
      password: $password
      dob: $dob
      profilePic: $profilePic
      country: $country
      countryCode: $countryCode
      phone: $phone
    ) {
      token
      user {
        id
        role
        firstName
        lastName
      }
    }
  }
`;
export const REGISTER_AS_MERCHANT = gql`
  mutation registerAsSeller(
    $firstName: String!
    $lastName: String!
    $email: String!
    $password: String!
    $dob: String!
    $country: String!
    $countryCode: String!
    $phone: String!
    $storeName: String!
    $street: String!
    $city: String!
    $state: String!
    $zipCode: String!
    $lon: String!
    $lat: String!
    $storeDescription: String
    $profilePic: String
    $coverPic: String
    $street2: String
  ) {
    registerAsSeller(
      firstName: $firstName
      lastName: $lastName
      email: $email
      password: $password
      dob: $dob
      country: $country
      countryCode: $countryCode
      phone: $phone
      storeName: $storeName
      street: $street
      city: $city
      state: $state
      zipCode: $zipCode
      lon: $lon
      lat: $lat
      storeDescription: $storeDescription
      profilePic: $profilePic
      coverPic: $coverPic
      street2: $street2
    ) {
      token
      user {
        id
        firstName
        lastName
        store {
          status
        }
      }
    }
  }
`;

export const BECOME_SELLER = gql`
  mutation BecomeSeller(
    $dob: String!
    $country: String!
    $countryCode: String!
    $phone: String!
    $storeName: String!
  ) {
    becomeSeller(
      dob: $dob
      country: $country
      countryCode: $countryCode
      phone: $phone
      storeName: $storeName
    ) {
      id
    }
  }
`;

export const sendOTPToEnableEmail2Factor = gql`
  mutation SendOTPToEnableEmail2Factor {
    sendOTPToEnableEmail2Factor
  }
`;

export const EnableEmail2factor = gql`
  mutation Mutation($otp: String!) {
    enableEmail2Factor(otp: $otp)
  }
`;

export const LoginWithOTP = gql`
  mutation LoginAsUserWithOtp($email: String!, $otp: String!) {
    loginAsUserWithOtp(email: $email, otp: $otp) {
      token

      user {
        id
        role
        firstName
        lastName
        profilePic
        dob
        email
        emailVerifiedAt
        country
        countryCode
        phone
        phoneVerifiedAt
        createdAt
        store {
          id
          id
          name
          status
        }
      }
    }
  }
`;
