import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import Logo from "../../components/header/Logo";
import IconGroup from "../../components/header/IconGroup";
import NavMenu from "../../components/header/NavMenu";
import MobileMenu from "../../components/header/MobileMenu";
import LanguageCurrencyChanger from "../../components/header/sub-components/LanguageCurrencyChanger";
import useAuth from "../../hooks/useAuth";
import { useQuery } from "@apollo/client";
import {
  GET_NOTIFICATIONS,
  GET_NOTIFICATIONS_SUBSCRIPTION,
} from "../../graphql/Notifications";
import { toast } from "react-toastify";

const HeaderTwo = () => {
  const [scroll, setScroll] = useState(0);
  const [headerTop, setHeaderTop] = useState(0);
  const currency = useSelector((state) => state.currency);
  const { user } = useAuth();

  const {
    data: notifications,
    error,
    subscribeToMore,
  } = useQuery(GET_NOTIFICATIONS, {
    variables: {
      all: true,
    },
  });

  useEffect(() => {
    const header = document.querySelector(".sticky-bar");
    setHeaderTop(header.offsetTop);
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleScroll = () => {
    setScroll(window.scrollY);
  };

  useEffect(() => {
    const abc = subscribeToMore({
      document: GET_NOTIFICATIONS_SUBSCRIPTION,
      variables: {
        userId: user?.id,
      },
      onError: (error) => {
        // console.log("error", error);
      },
      updateQuery: (prev, { subscriptionData }) => {
        if (!subscriptionData.data) {
          return prev;
        }

        const newFeedItem = subscriptionData.data.notificationReceived;
        toast.info(newFeedItem.body);
        return Object.assign({}, prev, {
          getNotifications: [newFeedItem, ...prev.getNotifications],
        });
      },
    });

    return abc;
  }, [user?.id, subscribeToMore]);
  return (
    <header className="header-area clearfix header-hm9 transparent-bar">
      <div className="container">
        <div className="header-top-area d-none d-lg-block">
          <div className="row">
            <div className="col-lg-5 col-md-8 col-12">
              {/* language currency changer */}
              <LanguageCurrencyChanger currency={currency} />
            </div>
            <div className="col-lg-2 d-none d-lg-block text-center">
              {/* header logo */}
              <Logo
                imageUrl="/assets/img/logo/logo.png"
                logoClass="logo-hm-9"
              />
            </div>
            <div className="col-lg-5 col-md-4 col-12">
              {/* Icon group */}
              <IconGroup notifications={notifications} error={error} />
            </div>
          </div>
        </div>
      </div>
      <div
        className={`header-bottom sticky-bar header-res-padding header-padding-2 ${
          scroll > headerTop ? "stick" : ""
        }`}
      >
        <div className="container">
          <div className="row align-items-center">
            <div className="col-5 d-block d-lg-none">
              {/* header logo */}
              <Logo imageUrl="/assets/img/logo/logo.png" />
            </div>
            <div className="col-1 d-block d-lg-none" />
            <div className="col-6 d-block d-lg-none pt-3">
              {/* Icon group */}
              <IconGroup notifications={notifications} error={error} />
            </div>
            <div className="col-xl-12 col-lg-12 d-none d-lg-block">
              {/* Nav menu */}
              <NavMenu />
            </div>
          </div>
          {/* mobile menu */}
          <MobileMenu />
        </div>
      </div>
    </header>
  );
};

export default HeaderTwo;
