import { Fragment } from "react";
import { Link } from "react-router-dom";
import { fToNow } from "../../../utils/DateTime";
import { useMutation } from "@apollo/client";
import {
  GET_NOTIFICATIONS,
  MARK_ALL_AS_READ,
  MARK_AS_READ,
} from "../../../graphql/Notifications";

const MenuNotifications = ({ notifications, error }) => {
  const [MARKASREAD] = useMutation(MARK_AS_READ, {
    refetchQueries: [{ query: GET_NOTIFICATIONS }],
  });
  const [markAllNotificationAsRead] = useMutation(MARK_ALL_AS_READ, {
    refetchQueries: [{ query: GET_NOTIFICATIONS, variables: { all: true } }],
  });
  return (
    <div className="shopping-cart-content">
      {notifications && notifications.length > 0 ? (
        <Fragment>
          {error && (
            <div class="alert alert-danger">
              <strong>error!</strong> {error?.message}
            </div>
          )}
          <ul>
            <li className="d-flex algin-items-center justify-content-end">
              <button
                className="btn btn-link  small  "
                style={{ fontSize: 13 }}
                onClick={markAllNotificationAsRead}
              >
                Mark all as Read
              </button>
            </li>
            {notifications.slice(0, 5).map((item) => {
              return (
                <li
                  onClick={() =>
                    MARKASREAD({
                      variables: {
                        notificationId: item.id,
                      },
                    })
                  }
                  className={`d-block ${item.isUnread ? "bg-gray" : ""}`}
                  key={item.id}
                  role="button"
                  aria-pressed="true"
                >
                  <NotificationItem type={item.type} data={item} />
                </li>
              );
            })}
            <li className="d-flex algin-items-center justify-content-center">
              <Link
                className="btn btn-primary btn-dark w-100  "
                to="/dashboard/notifications"
                role="button"
                aria-pressed="true"
              >
                See all
              </Link>
            </li>
          </ul>
        </Fragment>
      ) : (
        <p className="text-center">No notifications yet</p>
      )}
    </div>
  );
};

export default MenuNotifications;

const NotificationItem = ({ type, data }) => {
  switch (type) {
    case "LOGGEN_IN":
      return (
        <>
          <div className="shopping-cart-title">
            <h4>{data.title}</h4>
          </div>
          <div className="mt-1">
            <div className="row">
              <div className="col-md-8 ">
                <h6>{data.body}</h6>
              </div>
              <div className="col-md-4 small text-end ">
                <h6>{fToNow(new Date(data.createdAt))}</h6>
              </div>
            </div>
          </div>
        </>
      );
    case "NEW_MESSAGE":
      return (
        <>
          <Link to={"/dashboard/chat"}>
            <div className="shopping-cart-title">
              <h4>{data.title}</h4>
            </div>
            <div className="mt-1">
              <div className="row">
                <div className="col-md-8 ">
                  <h6>{data.body}</h6>
                </div>
                <div className="col-md-4 small text-end ">
                  <h6>{fToNow(new Date(data.createdAt))}</h6>
                </div>
              </div>
            </div>
          </Link>
        </>
      );
    case "review":
      return <span className="text-success">New review</span>;
    default:
      return <span className="text-info">New notification</span>;
  }
};
